<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString">
      <template v-slot:title>
        <div v-show="!waitingAPIResponse" class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <!-- <h1 class="cfa-section-title"><span class="cfa-primary">CAMPAGNE : </span>{{ groupDetails.detail.name }}</h1> -->
          <h1 class="cfa-section-title">
            <span class="cfa-primary">CAMPAGNE : </span>{{ campaignName }}
          </h1>
          <div v-if="status == 'created'">
          <span class="badge rounded-pill badge-danger- cfa-btn-gold p-2" style="font-size: .9em;">CAMPAGNE EN ATTENTE DE VALIDATION&nbsp;</span>
          </div>
          <div v-if="status == 'In progress'">
          <span class="badge rounded-pill badge-danger- cfa-btn-gold p-2" style="font-size: .9em;">CAMPAGNE EN COUR&nbsp;</span>
          </div>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="d-flex justify-content-between align-items-center mt-2">
                  <h5 class="mb-0 px-1">Informations sur le campagne</h5>
                  <!--<span class="badge rounded-pill badge-danger- cfa-btn-secondary p-3">CAMPAGNE EN COUR&nbsp;</span>-->
              </div>
              <div class="row mb-1 d-flex flex-wrap">
                    <div class="col-12 col-md-3 p-2">
                        <div class="bg-white d-flex m-2 py-3 gBlock">
                            <div class="p-3 gFolder-container">
                                <img width="30" src="@/assets/img/png/phishing.png" alt="dashboard state image"/>
                            </div>
                            <div>
                                <p>{{ orgName }}</p>
                                <p>Campagne de Phishing</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-3 p-2">
                        <div class="bg-white gBlock d-flex m-2 py-3">
                            <div class="p-3 gFolder-container">
                                <img width="30" src="@/assets/img/png/groupe.png" alt="dashboard state image"/>
                            </div>
                            <div>
                            <p>{{ groupLength }} utilisateur</p>
                                <p>dans le groupe</p>
                            </div>
                        </div>
                    </div>

                  <div class="col-12 col-md-3 p-2">
                      <div class="bg-white gBlock d-flex m-2 py-3">
                          <div class="p-3 gFolder-container">
                              <img width="30" src="@/assets/img/png/start.png" alt="dashboard state image"/>
                          </div>
                          <div>
                              <!--<p>03 Juin 2013, 08:00</p>-->
                              <p>{{ date == null ? "---" : date }}</p>
                              <p>à {{ hour }}</p>
                              <p>Date de lancement</p>
                          </div>
                      </div>
                  </div>

                  <div class="col-12 col-md-3 p-2">
                      <div class="bg-white gBlock d-flex m-2 py-3">
                          <div class="p-3 gFolder-container">
                              <img width="30" src="@/assets/img/png/time-left.png" alt="dashboard state image"/>
                          </div>
                          <div>
                              <!--<p>17 Juin 2013, 19:00</p>-->
                              <p>{{ dateEnd == null ? "---" : dateEnd }}</p>
                              <p>à {{ hourEnd }}</p>
                              <p>Date de fin</p>
                          </div>
                      </div>
                  </div>
            </div>

              <div class="row m-1" style="padding: 1.5em 0.5em; background: var(--primary-light-color-2); border-radius: 5px;">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div id="timeline_chart"></div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="d-flex justify-content-around flex-md-row flex-column">
                              <div class="text-center">
                                  <h6>Email envoyés</h6>
                                  <DoughnutChart1
                                          :valueCountUp="true"
                                          :valueCountUpDuration="700"
                                          :customPercentSize="100"
                                          :classValue="'chartTextStyle2'"
                                          :width="80"
                                          :height="80"
                                          time="yes"
                                          :percent="emailsEnvoyes  || '0'"
                                          :visibleValue="true"
                                          foregroundColor="rgb(3, 57, 60)"
                                          backgroundColor="#57b99c"
                                          :strokeWidth="15"
                                  />
                              </div>
                              <div class="text-center">
                                  <h6>Email ouverts</h6>
                                  <DoughnutChart1
                                          :valueCountUp="true"
                                          :valueCountUpDuration="700"
                                          :customPercentSize="100"
                                          :classValue="'chartTextStyle2'"
                                          :width="80"
                                          :height="80"
                                          time="yes"
                                          :percent="emailsOuverts  || '0'"
                                          :visibleValue="true"
                                          foregroundColor="rgb(3, 57, 60)"
                                          backgroundColor="#f0c056"
                                          :strokeWidth="15"
                                  />
                              </div>
                              <div class="text-center">
                                  <h6>Liens clickés</h6>
                                  <DoughnutChart1
                                          :valueCountUp="true"
                                          :valueCountUpDuration="700"
                                          :customPercentSize="100"
                                          :classValue="'chartTextStyle2'"
                                          :width="80"
                                          :height="80"
                                          time="yes"
                                          :percent="liensCliques  || '0'"
                                          :visibleValue="true"
                                          foregroundColor="rgb(3, 57, 60)"
                                          backgroundColor="#f0c056"
                                          :strokeWidth="15"
                                  />
                              </div>
                              <div class="text-center">
                                  <h6>Données soumises</h6>
                                  <DoughnutChart1
                                          :valueCountUp="true"
                                          :valueCountUpDuration="700"
                                          :customPercentSize="100"
                                          :classValue="'chartTextStyle2'"
                                          :width="80"
                                          :height="80"
                                          time="yes"
                                          :percent="donnesSoumises  || '0'"
                                          :visibleValue="true"
                                          foregroundColor="rgb(3, 57, 60)"
                                          backgroundColor="#df6556"
                                          :strokeWidth="15"
                                  />
                              </div>
                              <div class="text-center">
                                  <h6>Email  signalé</h6>
                                  <DoughnutChart1
                                          :valueCountUp="true"
                                          :valueCountUpDuration="700"
                                          :customPercentSize="100"
                                          :classValue="'chartTextStyle2'"
                                          :width="80"
                                          :height="80"
                                          time="yes"
                                          :percent="emailsSignales  || '0'"
                                          :visibleValue="true"
                                          foregroundColor="rgb(3, 57, 60)"
                                          backgroundColor="#df6556"
                                          :strokeWidth="15"
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="d-flex justify-content-end align-items-center my-3">
                  <!--<h6 class="mb-0 p-1">Dashbaord</h6>-->
                  <button class="btn cfa-btn-primary" disabled>
                      <i class="fa fa-download"></i>
                      GENERER LE RAPPORT
                  </button>
              </div>

              <h5 class="mb-1 p-1 mt-2">Details</h5>
              <div class="row px-0 m-1 tab-body">
                
                <div class="card">
            

            <DataTable ref="dt" :value="fields" v-model:selection="selectedProducts" dataKey="id"
                :paginator="true" :rows="10" :filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} campaign">
                <template #header>
                    <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
                        <h4 class="m-0"> Campaign Activate</h4>
						
					</div>
                </template>
               
                <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
                <Column field="name" header="Nom" sortable style="min-width:12rem"></Column>
               <Column field="launch_date" header="Date création" sortable style="min-width:10rem">
    <template #body="slotProps">
      {{ formatDate(slotProps.data.launch_date) }}
    </template>
  </Column>
                   <Column field="send_by_date" header="Date création" sortable style="min-width:10rem">
    <template #body="slotProps">
      {{ formatDate(slotProps.data.send_by_date) }}
    </template>
  </Column>
                
                <Column field="template_details.name" header="Template" sortable style="min-width:12rem"></Column>
                    <Column field="landing_page_details.name" header="Page" sortable style="min-width:12rem"> </Column>
                        <Column field="groups" header="Group Names" sortable style="min-width:12rem">
    <template #body="slotProps">
        <div v-if="slotProps.data.groups && slotProps.data.groups.length">
            <span v-for="(group, index) in slotProps.data.groups" :key="group.id">
                {{ group.name }}<span v-if="index < slotProps.data.groups.length - 1">, </span>
            </span>
        </div>
        <div v-else>No groups</div>
    </template>
</Column>

                        <Column field="smtp_details.name" header="SMTP" sortable style="min-width:12rem"> </Column>
                
                <Column :exportable="false" style="min-width:8rem,min-height:8rem" header="Actions">
                    <template #body="slotProps">
                        <!-- {slotProps.data.is_validated_by_admin ==false} ? <Button icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="getCallToAction(slotProps.data)" ><i class="fa  fa-pencil"></i></Button> :<h5 style="color:cyan;">validated</h5> -->
                         <Button v-if="slotProps.data.is_validated_by_admin ==false" icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="getCallToAction(slotProps.data)" ><i class="fa  fa-pencil">Edit</i></Button>
                         <Button v-else disabled icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="getCallToAction(slotProps.data)" ><i class="fa  fa-pencil">Edit</i></Button>
                         <Button icon="fa fa-trash" outlined rounded severity="danger" class="ms-2 btn btn-success" @click="confirmUpdates()" ><i class="fa fa-check">Validate</i></Button> 
                    </template>
                </Column>
            </DataTable>
        
          </div>
              </div>

              <h5 class="mb-1 p-1 mt-2">Utilisateurs</h5>
              <div class="row px-0 m-1 tab-body">
                  <table-expend class="px-0" max-th-width="0" last-cell-width="0" :allow-extension="false" :columns="resultColumns" :data="result" :columns-action="resultToListen" classFilter="" @action="getCallToAction"></table-expend>
              </div>
            </div>
        </div>
         
        <!-- edit campaign -->
        <scalable-modal @callback="confirmUpdate" :break="exitEditModal" label="EDITER LA CAMPAGNE" @close="openUpdateModal=false" :open="openUpdateModal" :advancedControl="true" validationButtonText="Enregistrer" exitButtonText="Annuler" display-mode="flex" :style="'width: 40em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
                <!-- blo1 -->
                <div v-if="tempUpdateID == null" class="w-100">

                <div class="row w-100 m-inherit" style="margin-top: 2vh; margin-bottom: 2vh;">
                    <div class="form-group py-2 ">
                    <p class="mb-2"><strong>Nom</strong></p>
                    <div class="d-flex flex-row">
                        <input class="form-control" type="text" v-model="updateDataPayload.name">
                    </div>
                    </div>
                    
                </div>

                <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2 ">
                    <p class="mb-2"><strong>Template</strong></p>
                    <div class="d-flex flex-row">
                        <select class="form-control" v-model="newTemp">
                            <option disabled selected value="">{{ updateDataPayload.template_details.name }}</option>
                            <option v-for="template in filteredTemplate" :key="template.id" :value="template.id">{{ template.name }}</option>
                        </select>
                    </div>
                    </div>
                    </div>
                    <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2 ">
                    <p class="mb-2"><strong>Page</strong></p>
                    <div class="d-flex flex-row">
                        <select class="form-control" v-model="newPage">
                            <option selected disabled value="">{{ updateDataPayload.landing_page_details.name }}</option>
                            <option v-for="page in filteredLandings" :key="page.id" :value="page.id">{{ page.name }}</option>
                        </select>
                    </div>
                    </div>
                </div>
                 <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2 ">
                    <p class="mb-2"><strong>URL<e style="color: red">*</e></strong></p>
                    <div class="d-flex flex-row">
                        <input class="form-control" type="text" v-model="updateDataPayload.url">
                    </div>
                    </div>
                    </div> 
                <div class="row w-100 m-inherit" style="margin-top: 2vh; margin-bottom: 2vh;">
                    <div class="form-group py-2 ps-0 pe-0 pe-md-2 col-6">
                    <p class="mb-2"><strong>Date de lancement</strong></p>
                    <div class="d-flex flex-row">
                        <!-- <input class="form-control" type="datetime-local" v-model="updateDataPayload.launch_date"> -->
                        <VueDatePicker :placeholder="updateDataPayload.launch_date == null ? 'NON DEFINI' : formatDate(updateDataPayload.launch_date)" v-model="updateDataPayload.launch_date" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'"></VueDatePicker>
                    </div>
                    </div>
                    <div class="form-group py-2 col-6 px-0">
                    <p class="mb-2"><strong>Date de fin</strong></p>
                    <div class="d-flex flex-row">
                        <!-- <input class="form-control" type="datetime-local" v-model="updateDataPayload.send_by_date"> -->
                        <VueDatePicker :placeholder="updateDataPayload.send_by_date == null ? 'NON DEFINI' : formatDate(updateDataPayload.send_by_date)" v-model="updateDataPayload.send_by_date" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" ></VueDatePicker>
                    </div>
                    </div>
                </div>

                
                    <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2">
                    <p class="mb-2"><strong>SMTP<e style="color: red">*</e></strong></p>
                    <div class="d-flex flex-row">
                        <select class="form-control" v-model="updateDataPayload.gophish_smtp_id">
                            <option selected disabled value="">select smtp</option>
                            <option v-for="page in smtp" :key="page.id" :value="page.id">{{ page.name }}</option>
                        </select>
                    </div>
                    </div>
                </div>
                <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2">
                    <p class="mb-2"><strong>Groupe</strong></p>
                    <div class="d-flex flex-row">
                        <select class="form-control" v-model="newGroup">
                            <option selected disabled value="">{{ updateDataPayload.groups[0].name }}</option>
                            <option v-for="group in filteredGroups" :key="group.id" :value="group.id">{{ group.name }}</option>
                        </select>
                    </div>
                    </div>
                </div>

                </div>
                <div v-else>
                <div class="cfa-page">
                    <div class="d-flex justify-content-center align-items-center" style="min-height: 40vh">
                    <div class="preloader_center d-flex flex-column">
                        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                        <p class="pl-3 fw-bold">chargement . . .</p>
                    </div>
                    </div>
                </div>
                </div>
            </template>
        </scalable-modal>

        <div v-show="waitingAPIResponse" class="cfa-page">
            <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
                <div class="preloader_center d-flex flex-column">
                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                    <p class="pl-3 fw-bold">chargement . . .</p>
                </div>
            </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
//import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import DoughnutChart1 from '@/components/shared/chart/DoughnutChart1.vue'
import TableExpend from "@/components/shared/table/TableExpend.vue";
// import Table from "@/components/shared/table/Table.vue";
import {getAPI} from "@/axios-api";
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
                   


// import Table from "@/components/shared/table/Table.vue";
export default {
  name: 'DashboardView',
  components: {
    //CFAFooter,
    CFANav,
    DataTable,
    Column,
    DoughnutChart1,
    // Table,
    TableExpend,
    ScalableModal,
    VueDatePicker
  },
  data () {
      return {
          waitingAPIResponse: true,
          result: [],
          selectedProducts:'',
          filters: {},
          resultColumns: ['Nom & Prénoms', 'Email', 'Position', 'Telephone', 'Pays', 'Statut'],
          fields: [],
          fieldsColumns: ['Nom', 'Date de démarrage', 'Date de fin', 'Template','Page', 'Groupe', 'Actions'],
          fieldsToListen: ['Actions'],
          resultToListen: [''],
          //groupDetails: null,
          groupDetails: { data: {} },
          emailsEnvoyes: 0,
          emailsOuverts: 0,
          liensCliques: 0,
          donnesSoumises: 0,
          emailsSignales: 0,
          groupId: "",
          groupLength: 0,
          groupN: "",
          groupName: "",
          exitEditModal: null,
          openUpdateModal: false,
          tempUpdateID: null,
          /*updateDataPayload: {
            gophish_landing_page_id : null,
            gophish_template_id : null,
            gophish_smtp_id : null,
            send_by_date : null,
            launch_date : null,
            url : null,
            organisation: {
              id: null,
            }
          },*/
          updateDataPayload: null,
          templateData: [],
          templateData1: [],
          newCampaignDateStart: null,
          newCampaignDateEnd: null,
          allGroups: [],
          newGroup: "",
          templateName: "",
          newTemp: "",
          allTemplates: [],
          newPage: "",
          pageName: "",
          allLandings: [],
          launchDate: null,
          launchD: null,
          startDate: null,
          startDateD: null,
          status: "",
          campaignName: "",
          orgName: "",
          date: "",
          hour: "",
          dateEnd: "",
          hourEnd: "",
          smtp:[],
          alertString: null,
          groupUsers: null

      }
  },
  computed: {
    
    filteredTemplate() {
      return this.allTemplates.filter(temp => temp.name !== this.updateDataPayload.template_details.name);
    },
    filteredLandings() {
      return this.templateData1.filter(land => land.name !== this.updateDataPayload.landing_page_details.name);
    },
    filteredGroups() {
      return this.allGroups.filter(group => group.name !== this.updateDataPayload.groups[0].name);
    },
  },
  methods: {
    
    formatDate(date) {
        const formattedDate = new Date(date).toLocaleString();
        return formattedDate;
    },
    getAllTemplates(){
        getAPI.get(`phishing/templates/`)
          .then(response => {
            if(response.status == 200) {
              this.allTemplates = response.data.data;
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPITemplate = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              //this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPITemplate = false
        })
    },
    getTemplate () {
      this.waitingAPITemplate = true
      getAPI.get(`phishing/templates/`)
          .then(response => {
              if(response.status == 200) {
                  this.templateData = response.data.data;
                  this.templateName = this.templateData[0].name
              }
              else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                  this.waitingAPITemplate = false
              }, 2000);
          })
          .catch(error => {
              if (error.response) {
                  //this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                  //let msg = 'The request was made but no response was received. Please check your network.'
                  //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPITemplate = false
          })
        },
        getLanding () {
            this.waitingAPITemplate = true
            getAPI.get(`phishing/landing-pages/`)
                .then(response => {
                    if(response.status == 200) {
                        this.templateData1 = response.data.data;
                        this.pageName = this.templateData1[0].name
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        geSmtp () {
            this.waitingAPITemplate = true
            getAPI.get(`phishing/sending-profiles/`)
                .then(response => {
                    if(response.status == 200) {
                        this.smtp = response.data.data;
                        
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        getLandings () {
            getAPI.get(`phishing/landing-pages/`)
                .then(response => {
                    if(response.status == 200) {
                        this.allLandings = response.data.data;
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
    updateData (pos) {
      this.updateDataPayload = pos
      this.openUpdateModal = true
    },
    updateDatas (pos) {
      this.updateDataPayload = pos
      //this.openUpdateModal = true
      this.confirmUpdates ();
    },
    getCallToActionss () {
        
            getAPI.get(`phishing/campaign/${this.$route.params.id}/`)
            .then(response => {
              if(response.status === 200) {
                let data = response.data.data.detail
                //this.updateDatas(data);
                this.matchUpdatedData(data)
                console.log("Datta: ", data)
                

              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
            .catch(error => {
              if (error.response) {
                //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
        
      },
      getCallToAction (payload) {
        this.openUpdateModal = true
        return
        //eslint-disable-next-line
        console.log(payload);
            getAPI.get(`phishing/campaign/${this.$route.params.id}/`)
            .then(response => {
              if(response.status === 200) {
                let data = response.data.data.detail
                //this.updateData(data);
                console.log("Daaaaaaaaaaataaaaaaaaaaaaaa: ", data)
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
            .catch(error => {
              if (error.response) {
                //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
        
      },
       getCallToActions (payload) {
        if(payload.action_type === 'edit') {
            getAPI.get(`phishing/campaign/${this.$route.params.id}/`)
            .then(response => {
              if(response.status === 200) {
                let data = response.data.data
                this.updateData(data);
                console.log("Daaaaaaaaaaataaaaaaaaaaaaaa: ", data)
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
            .catch(error => {
              if (error.response) {
                //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
        }
      },
      confirmUpdates ( ){
        const group = [];
        if (!this.updateDataPayload || !this.updateDataPayload.url || this.updateDataPayload.url == "" || !this.updateDataPayload.gophish_smtp_id) {
          console.log("nope")
          this.alertString = `Veuillez specifier l'url et le SMTP|alert-no|10000::`+Date.now()
          return
        }
        group.push(this.newGroup || this.updateDataPayload.groups[0].id);
        getAPI.patch(`phishing/campaign/${this.$route.params.id}/`, {
            name: this.updateDataPayload.name,
           // page: this.newPage || this.updateDataPayload.landing_page_details.id,
           // template: this.newTemp || this.updateDataPayload.template_details.id,
            //smtp:this.newSmtp || this.updateDataPayload.smtp.id,
            groups: group,
            gophish_landing_page_id:this.updateDataPayload.gophish_landing_page_id,
            gophish_template_id:this.updateDataPayload.gophish_template_id,
            gophish_smtp_id:this.updateDataPayload.gophish_smtp_id,

            send_by_date: this.updateDataPayload.send_by_date,
            launch_date: this.updateDataPayload.launch_date,
            //send_by_date: this.newCampaignDateEnd,
            //lauch_date: this.newCampaignDateStart,
            url: this.updateDataPayload.url,
            organisation: this.updateDataPayload.organisation.id,
            //status: this.updateDataPayload.status,
            is_validated_by_admin: true
          })
            .then(response => {
              if(response.status === 200) {
                // let data = response.data.data
                // this.updateData(data);
                window.location.reload();
                this.getCampaignDetails();
                // let msg = 'Campaign validated successfully !'
                // this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitEditModal = true
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitEditModal = true
              }
            })
            .catch(error => {
              this.exitEditModal = true
              if (error.response) {
                //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
      },
      confirmUpdate ( ){
        const group = [];
        group.push(this.newGroup || this.updateDataPayload.groups[0].id);
        getAPI.patch(`phishing/campaign/${this.$route.params.id}/`, {
            name: this.updateDataPayload.name,
           // page: this.newPage || this.updateDataPayload.landing_page_details.id,
           // template: this.newTemp || this.updateDataPayload.template_details.id,
            //smtp:this.newSmtp || this.updateDataPayload.smtp.id,
            groups: group,
            gophish_landing_page_id:this.updateDataPayload.gophish_landing_page_id,
            gophish_template_id:this.updateDataPayload.gophish_template_id,
            gophish_smtp_id:this.updateDataPayload.gophish_smtp_id,

            send_by_date: this.updateDataPayload.send_by_date,
            launch_date: this.updateDataPayload.launch_date,
            //send_by_date: this.newCampaignDateEnd,
            //lauch_date: this.newCampaignDateStart,
            url: this.updateDataPayload.url,
            organisation: this.updateDataPayload.organisation.id,
            //status: this.updateDataPayload.status,
            is_validated_by_admin: false
          })
            .then(response => {
              if(response.status === 200) {
                // let data = response.data.data
                // this.updateData(data);
                window.location.reload();
                this.getCampaignDetails();
                let msg = 'Campaign updated successfully !'
                this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                this.exitEditModal = true
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitEditModal = true
              }
            })
            .catch(error => {
              this.exitEditModal = true
              if (error.response) {
                //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
      },
      matchUpdatedData (data) {
            console.log(this.updateDataPayload, data)
            this.updateDataPayload = data
            //this.updateDataPayload.gophish_landing_page_id = data.gophish_landing_page_id
            //this.updateDataPayload.gophish_template_id = data.gophish_template_id
            //this.updateDataPayload.gophish_smtp_id = data.gophish_smtp_id
            //this.updateDataPayload.send_by_date = data.send_by_date
            //this.updateDataPayload.launch_date = data.launch_date
            //this.updateDataPayload.url = data.url
            //this.updateDataPayload.organisation.id = data.organisation.id
      },
      convertDate(dateString, dateOnly=false) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            if(!dateOnly) {
                return `<b>${formattedDate} à ${formattedTime}</b>`;
            }
            return `<b>${formattedDate}</b>`;
        },
      getResult () {
        this.waitingAPIResponse = true
          getAPI.get(`phishing/campaign/${this.$route.params.id}/`)
              .then(response => {
                  if(response.status == 200) {
                      this.groupDetails = response.data.data.summary;

                      // stats
                      this.emailsEnvoyes = this.groupDetails.stats.sent,
                      this.emailsOuverts = this.groupDetails.stats.opened,
                      this.liensCliques = this.groupDetails.stats.clicked,
                      this.donnesSoumises = this.groupDetails.stats.submitted_data
                      this.emailsSignales = this.groupDetails.stats.email_reported

                    //   const campaignDetails = this.groupDetails;
                    //   console.log("Usersgroup: ", campaignDetails);
                    //   this.fields = [];
                    //   for(let i = 0; i < 3; i++){
                    //     //let row = campaignDetails[i];
                    //     this.fields.push({
                    //         'Nom': "Hahahaha",
                    //         // 'Nom': `${row.name}`,
                    //         // 'Date d\'envoi':  `${row.send_by_date}`,
                    //         // 'Date de démarrage':  `${row.name}`,
                    //         // 'Url':  `${row.name}`,
                    //         // 'Page':  `${row.name}`,
                    //         // 'Template':  `${row.name}`,
                    //         // 'Smtp':  `${row.name}`,
                    //         // 'Groupe':  `${row.name}`,

                    //         // 'Nom & Prénoms': `${row.name}`,
                    //         // 'Email': 'genereux2222222222.akotenou@gemail.com',
                    //         // 'Position': 'Eng FullStack',
                    //         // 'Statut':"<button style='width: 100%' class='btn cfa-btn-primary disable'>Email envoyé</button>",
                    //     });
                    //   }
                  }
                  else {
                      //let msg = 'Oups ! something went wrong.'
                      //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  setTimeout(() => {
                      this.waitingAPIResponse = false
                  }, 2000);
              })
              .catch(error => {
                  if (error.response) {
                      //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                  }
                  else if (error.request) {
                      //let msg = 'The request was made but no response was received. Please check your network.'
                      //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                  }
                  else {
                      //let msg = 'Oups ! something went wrong.'
                      //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  this.waitingAPIResponse = false
              })

            //   {
            //       'Nom & Prénoms': 'Généreux Akotenou',
            //       'Email': 'genereux.akotenou@gemail.com',
            //       'Position': 'Eng FullStack',
            //       'Statut':"<button style='width: 100%' class='btn cfa-btn-primary disable'>Email envoyé</button>",
            //       'Reported': ''
            //   }
          setTimeout(() => {
              // this.waitingAPIResponse = false
          }, 2000)
      },
    //   getResult () {
    //       this.waitingAPIResponse = true
    //       this.result = [
    //           {
    //               'Nom & Prénoms': 'Généreux Akotenou',
    //               'Email': 'genereux.akotenou@gemail.com',
    //               'Position': 'Eng FullStack',
    //               'Statut':"<button style='width: 100%' class='btn cfa-btn-primary disable'>Email envoyé</button>",
    //               'Reported': ''
    //           },
    //           {
    //               'Nom & Prénoms': 'Généreux Akotenou',
    //               'Email': '<a href="mailto:genereux.akotenou@gemail.com">genereux.akotenou@gemail.com</a>',
    //               'Position': 'Eng FullStack',
    //               'Statut':"<button style='width: 100%' class='btn cfa-btn-primary disable'>Email envoyé</button>",
    //               'Reported': ''
    //           },
    //           {
    //               'Nom & Prénoms': 'Généreux Akotenou',
    //               'Email': '<a href="mailto:genereux.akotenou@gemail.com">genereux.akotenou@gemail.com</a>',
    //               'Position': 'Eng FullStack',
    //               'Statut':"<button style='width: 100%' class='btn btn-danger disable'>Données soumises</button>",
    //               'Reported': ''
    //           },
    //           {
    //               'Nom & Prénoms': 'Généreux Akotenou',
    //               'Email': '<a href="mailto:genereux.akotenou@gemail.com">genereux.akotenou@gemail.com</a>',
    //               'Position': 'Eng FullStack',
    //               'Statut':"<button style='width: 100%' class='btn cfa-btn-primary disable'>Email envoyé</button>",
    //               'Reported': ''
    //           },
    //           {
    //               'Nom & Prénoms': 'Généreux Akotenou',
    //               'Email': '<a href="mailto:genereux.akotenou@gemail.com">genereux.akotenou@gemail.com</a>',
    //               'Position': 'Eng FullStack',
    //               'Statut':"<button style='width: 100%' class='btn cfa-btn-primary disable'>Email envoyé</button>",
    //               'Reported': ''
    //           },
    //           {
    //               'Nom & Prénoms': 'Généreux Akotenou',
    //               'Email': '<a href="mailto:genereux.akotenou@gemail.com">genereux.akotenou@gemail.com</a>',
    //               'Position': 'Eng FullStack',
    //               'Statut':"<button style='width: 100%' class='btn cfa-btn-primary disable'>Email envoyé</button>",
    //               'Reported': ''
    //           },
    //           {
    //               'Nom & Prénoms': 'Généreux Akotenou',
    //               'Email': '<a href="mailto:genereux.akotenou@gemail.com">genereux.akotenou@gemail.com</a>',
    //               'Position': 'Eng FullStack',
    //               'Statut':"<button style='width: 100%' class='btn cfa-btn-primary disable'>Email envoyé</button>",
    //               'Reported': ''
    //           },
    //       ];
    //       setTimeout(() => {
    //           // this.waitingAPIResponse = false
    //       }, 2000)
    //   },
      getAllGroups() {
        getAPI.get(`groupes/`)
          .then(response => {
            if(response.status == 200) {
              this.allGroups = response.data.data
              console.log("reponse: ", this.allGroups)
            }
            else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
                this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
                //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
        })
      },
      splitGroup(group) {
        const parties = group.split("_");
        const gpName = parties[0];
        return gpName;
      },
      formatDateForCampaign() {
        const dateHeure = this.launchD.split(" ");
        return dateHeure;
      },
      formatDateForCampaign2() {
        const dateHeure = this.startDateD.split(" ");
        return dateHeure;
      },
      getCampaignDetails() {
        this.waitingAPIResponse = true;
        getAPI.get(`phishing/campaign/${this.$route.params.id}`)
            .then(response => {
              console.log(response)
                if (response.status === 200) {
                    let row = response.data.data.detail;
                    this.fields.push(row);
                    console.log(this.fields);
                    
                    this.groupDetails = response.data.data.detail;
                    
                    // this.status = this.groupDetails.status;
                    this.campaignName = this.groupDetails.name;
                    this.groupDetailsData = response.data.data.detail;
                    this.orgName = this.groupDetailsData.name;
                    this.groupId = this.groupDetailsData.groups[0].id;
                    const gpName = this.groupDetailsData.groups[0].name;
                    console.log("Usersgroup: ", gpName);
                    
                    this.launchD = this.formatDate(row.launch_date, true);
                    this.startDateD = this.formatDate(row.send_by_date, true);
                    this.date = this.formatDateForCampaign()[0];
                    this.hour = this.formatDateForCampaign()[1];
                    this.dateEnd = this.formatDateForCampaign2()[0];
                    this.hourEnd = this.formatDateForCampaign2()[1];
                    this.getGroupUsers(row.groups[0].id)
                    // this.fields = []; // Ensure it's cleared before pushing new data
                    // this.fields.push({
                    //     'Nom': row.name,
                    //     'Date de démarrage': this.convertDate(row.launch_date, true),
                    //     'Date de fin': this.convertDate(row.send_by_date, true),
                    //     'Template': row.template_details.name,
                    //     'Page': row.landing_page_details.name,
                    //     'Groupe': this.splitGroup(gpName),
                    //     'Actions': row.is_validated_by_admin ? 
                    //         `<button disabled title="IMPOSSIBLE DE MODIFIER LA CAMPAGNE" btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-secondary btn-action"><i class="fa fa-edit"></i>edit</button>` :
                    //         `<button title="MODIFIER LES DETAILS DE LA CAMPAGNE" btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-secondary btn-action"><i class="fa fa-edit"></i>edit</button>`
                    // });
                    console.log('******', this.fields);
                } else {
                    this.alertString = 'Oups ! something went wrong.|alert-no|5000::' + Date.now();
                }
                setTimeout(() => {
                    this.waitingAPIResponse = false;
                }, 2000);
            })
            .catch(error => {
                console.log(error);
                this.waitingAPIResponse = false;
            });
    },
      
      getGroupUsers (groupId) {
          this.waitingAPIResponse = true
          getAPI.get(`groupes/${groupId}`)
              .then(response => {
                console.log(response)
                  if(response.status == 200) {
                      this.groupUsers = response.data;
                      this.userDetails = this.groupUsers.user_detail
                      this.groupN = this.groupUsers.name
                      console.log("Group name: ", this.groupN)
                      //this.groupName = this.splitGroup(this.groupN)
                      this.groupLength = this.userDetails.length
                      this.result = [];
                    for(let i = 0; i < this.userDetails.length; i++){
                        let row = this.userDetails[i];
                        this.result.push({
                            'Nom & Prénoms':`${row.lastname + " " + row.firstname}`,
                            'Email': `${row.email}`,
                            'Position': `${row.role.name == "manager" ? "MANAGER" : "MEMBRE" || "NON DEFINI"}`,
                            'Telephone': `${row.phone}`,
                            'Pays': `${row.country}`,
                            'Statut': `${row.status || "- - -"}`,
                        });
                    }
                  }
                  else {
                      //let msg = 'Oups ! something went wrong.'
                      //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  setTimeout(() => {
                      this.waitingAPIResponse = false
                  }, 2000);
              })
              .catch(error => {
                  if (error.response) {
                      //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                  }
                  else if (error.request) {
                      //let msg = 'The request was made but no response was received. Please check your network.'
                      //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                  }
                  else {
                      //let msg = 'Oups ! something went wrong.'
                      //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  this.waitingAPIResponse = false
              })
      },
  },
  mounted() {
    this.getResult();
    this.getCampaignDetails();
    //this.getGroupUsers();
    this.getTemplate();
    this.getLanding();
    this.getAllGroups();
    this.getAllTemplates();
    this.geSmtp ();
    this.getCallToActionss();
    //this.getLandings();
    
  }
}
</script>

<style scoped>
.cfa-page{}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.gFolder-container{
    background-color: var(--primary-light-color-2);
    border-radius: 0.5em;
    height: 4.3em;
    width: 4.6em;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gBlock{
    align-items: center;
    justify-content: start;
    border-radius: 0.6em;
    padding-left: 2em;
    padding-right: 2em;
    height: 8em !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.gBlock div:last-of-type p{
    margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
    font-weight: bold;
    font-size: 1.4em;
}
</style>
